/** Cookies accept **/

const COOKIE_CONSENT_KEY = 'mySite_cookieAccept';

function checkCookies() {
	if (typeof Storage !== 'undefined') {
		if (localStorage.getItem(COOKIE_CONSENT_KEY) === 'cookies-accept') {
			document.querySelectorAll('[data-cookie-msg]').forEach(element => {
				element.remove(); // Удаление элемента из DOM
			});
		}
	}
}

document.addEventListener('DOMContentLoaded', checkCookies);

let cookieAcceptDebounce;
document.addEventListener("click", function (event) {
	if (event.target.matches('[data-cookie-accept]')) {
		event.preventDefault(); // Предотвращаем действие по умолчанию
		event.stopPropagation(); // Предотвращаем всплытие события

		clearTimeout(cookieAcceptDebounce);
		cookieAcceptDebounce = setTimeout(() => {
			localStorage.setItem(COOKIE_CONSENT_KEY, 'cookies-accept');
			checkCookies();
		}, 300);
	}
});

/** END Cookies accept **/
