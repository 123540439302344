function changeText(phrases) {
	const lines = document.querySelectorAll('.line');

	const newPhrase = phrases[currentIndex];

	lines.forEach((span, index) => {
		const oldText = span.querySelector('.text.old-text');
		const newText = document.createElement('span');
		newText.classList.add('text', 'new-text');
		newText.innerText = newPhrase.data[index];

		span.appendChild(newText);

		// Добавляем задержку перед анимацией для каждого нового слова
		setTimeout(() => {
			// Триггер анимации
			requestAnimationFrame(() => {
				newText.style.transform = 'translateY(0)';
				if (oldText) {
					oldText.style.transform = 'translateY(-100%)';
				}
			});

			setTimeout(() => {
				if (oldText) {
					oldText.remove();
				}


				newText.classList.remove('new-text');
				newText.classList.add('old-text');

				// Проверяем, если это последнее слово в текущей фразе
				if (index === newPhrase.data.length - 1) {
					// Устанавливаем задержку перед переходом к следующей фразе
					setTimeout(() => {
						currentIndex = (currentIndex + 1) % phrases.length;
						changeText(phrases); // Рекурсивный вызов для следующей фразы
					}, 10); // Задержка между фразами
				}
			}, parseInt(newPhrase.timeout)); // Длительность анимации
		}, index * 200); // Задержка для каждого слова
	});
}
