/** logo animation **/
let currentIndex = 0;
//inache ne rabotaet!
if(typeof phrases==="undefined") { //fallback to phrases
	let phrases = [
		{timeout: 5000, data: ["Orange", "Label", "DIGITAL", "Design"]},
		{timeout: 5000, data: ["Orange", "Label", "Design", "& devs"]},
		{timeout: 5000, data: ["Orange", "Label", "WEBSite", "Design"]},
		{timeout: 5000, data: ["Orange", "Label", "WEBSite", "DEVS"]},
		{timeout: 5000, data: ["Orange", "Label", "APP", "Design"]},
		{timeout: 5000, data: ["Orange", "Label", "MOBILE", "DEVS"]},
		{timeout: 5000, data: ["Orange", "Label", "LOGO &", "BRANDS"]},
		{timeout: 5000, data: ["Orange", "Label", "LTD", ""]},
		{timeout: 5000, data: ["Orange", "Label", "дesign", "StuDio"]},
		{timeout: 5000, data: ["橙色", "标签", "设计", "工作室"]},

	];
	changeText(phrases);
} else {
	changeText(phrases);
}
// Начинаем смену текста сразу после загрузки страницы - скрипт в папке vendors

/** end logo animation **/

document.addEventListener("DOMContentLoaded", function() {
	// Убедитесь, что GSAP и ScrollTrigger загружены
	if (typeof gsap === 'undefined' || typeof ScrollTrigger === 'undefined') {
		console.error('GSAP или ScrollTrigger не загружены.');
		return;
	}

	// Регистрация плагина ScrollTrigger
	gsap.registerPlugin(ScrollTrigger, ScrollToPlugin);

	// Получаем все секции с классом section
	const sections = document.querySelectorAll('.section');
	sections.forEach((section, index) => {
		const mobile = ('mobile');
		const hideElements = ('hide-elements');
		const scrollDown = section.querySelector('[data-scroll-down]');
		// ScrollTrigger для изменения класса на body
		ScrollTrigger.create({
			trigger: section,
			start: "top center",
			end: "bottom center",
			markers: false,
			onEnter: () => {
				const darkClass = section.dataset.dark;
				if (darkClass) {
					document.body.classList.add(darkClass);
				}
			},
			onLeave: () => {
				const darkClass = section.dataset.dark;
				if (darkClass) {
					document.body.classList.remove(darkClass);
				}
			},
			onEnterBack: () => {
				const darkClass = section.dataset.dark;
				if (darkClass) {
					document.body.classList.add(darkClass);
				}
			},
			onLeaveBack: () => {
				const darkClass = section.dataset.dark;
				if (darkClass) {
					document.body.classList.remove(darkClass);
				}
			}
		});



		const mainImg = section.querySelector('[data-main-img] ');
		if (mainImg) {
			gsap.fromTo(
				mainImg,
				{ opacity: 1 },
				{
					opacity: 0,
					scrollTrigger: {
						trigger: "#main",
						start: 'top top',
						end: 'bottom top',
						scrub: true
					}
				}
			);
			// Анимация изменения object-position для всех элементов .background-img в секции #main
			gsap.utils.toArray("#main .background-img").forEach(img => {
				gsap.fromTo(
					img,
					{ objectPosition: '0% 0%' },  // начальное состояние
					{
						objectPosition: '100% 0%',  // конечное состояние
						scrollTrigger: {
							trigger: "#main", // элемент, который будет триггером
							start: 'top top', // начало анимации
							end: 'bottom top', // конец анимации
							scrub: true // плавная анимация при прокрутке
						}
					}
				);
			});
		}



		// ScrollTrigger для анимации элемента "scroll down"
		if (scrollDown) {
			ScrollTrigger.create({
				trigger: section,
				markers: false,
				start: "bottom bottom", // Задержка на 100px перед началом анимации
				onEnter: () => {
					// Анимация для элемента "scroll down"
					gsap.to(scrollDown, { y: -150, opacity: 0, duration: 1 });
				},
				onEnterBack: () => {
					// Анимация для элемента "scroll down" при обратном скролле
					gsap.to(scrollDown, { y: -150, opacity: 0, duration: 1 });
				},
				onLeaveBack: () => {
					// Сброс анимации для элемента "scroll down" при обратном скролле
					gsap.to(scrollDown, { y: 0, opacity: 1, duration: 1 });
				}
			});
		}
		// Main Img animation
		ScrollTrigger.create({
			trigger: ("#main"),
			markers: false,
			start: 'top center',
			end: 'bottom 60%',
			toggleActions: "play reverse play reverse",
			onEnter: () => {
				document.body.classList.remove(mobile);
			},
			onLeave: () => {
				document.body.classList.add(mobile);
			},
			onEnterBack: () => {
				document.body.classList.remove(mobile);
			},
			onLeaveBack: () => {
				document.body.classList.add(mobile);
			}
		});
		ScrollTrigger.create({
			trigger: ("#project02"),
			markers: false,
			start: 'top center',
			end: 'bottom center',
			toggleActions: "play reverse play reverse",
			onEnter: () => {
				document.body.classList.add(hideElements);
			},
			onLeave: () => {
				document.body.classList.remove(hideElements);
			},
			onEnterBack: () => {
				document.body.classList.add(hideElements);
			},
			onLeaveBack: () => {
				document.body.classList.remove(hideElements);
			}
		});
	});







	let links = gsap.utils.toArray(".page-menu a");
	links.forEach(a => {
		let element = document.querySelector(a.getAttribute("href")),
			linkST = ScrollTrigger.create({
				trigger: element,
				start: "top top"
			});
		ScrollTrigger.create({
			trigger: element,
			start: "top center",
			end: "bottom center",
			markers: false,
			onToggle: self => self.isActive && setActive(a)
		});
		a.addEventListener("click", e => {
			e.preventDefault();
			gsap.to(window, {duration: 1, scrollTo: linkST.start, overwrite: "auto"});
		});
	});

	function setActive(link) {
		links.forEach(el => el.classList.remove("active"));
		link.classList.add("active");
	}


	// const fixedImgs = document.querySelectorAll('.img-bg-fixed');
	//
	// fixedImgs.forEach(fixedImg => {
	// 	if (fixedImg) {
	// 		gsap.to(fixedImg, {
	// 			y: "50%",  // Смещение по вертикали для параллакс-эффекта
	// 			scrollTrigger: {
	// 				trigger: fixedImg.closest('.section'), // Привязка к родительской секции
	// 				start: 'top top',  // Начало анимации
	// 				end: 'bottom top', // Конец анимации
	// 				markers: false,    // Отключение маркеров (поставь true, если нужно видеть их)
	// 				scrub: true        // Плавная анимация при прокрутке
	// 			}
	// 		});
	// 	}
	// });




// Parallax
	function initParallax() {
		gsap.utils.toArray(".img-parallax").forEach(layer => {
			const speed = layer.dataset.speed;
			gsap.to(layer, {
				y: () => -(layer.parentElement.offsetHeight * speed),
				ease: "none",
				scrollTrigger: {
					trigger: layer.parentElement,
					start: "top bottom",
					end: "bottom top",
					scrub: true
				}
			});
		});
	}

	function initAnimations() {

		// Reinitialize form animation
		const sectionsForm = document.querySelector('[data-section-form]');
		const form = sectionsForm.querySelector('[data-form]');
		const feedbackSection = 'feedback-section';
		if (form) {
			gsap.fromTo(
				form,
				{
					x: 1500
				},
				{
					x: 0,
					duration: 1,
					scrollTrigger: {
						trigger: sectionsForm,
						start: 'top center',
						end: 'bottom 60%',
						toggleActions: "play none play none",
						onEnter: () => {
							document.body.classList.add(feedbackSection);
						},
						onLeave: () => {
							document.body.classList.remove(feedbackSection);
						},
						onEnterBack: () => {
							document.body.classList.add(feedbackSection);
						},
						onLeaveBack: () => {
							document.body.classList.remove(feedbackSection);
						}

					}
				}
			);
		}

		// Плавная прокрутка к секциям по якорям
		document.querySelectorAll('a[href^="#"]').forEach(anchor => {
			anchor.addEventListener('click', function(e) {
				e.preventDefault();
				const targetId = this.getAttribute('href');
				const targetElement = document.querySelector(targetId);

				if (targetElement) {
					gsap.to(window, {scrollTo: targetElement, duration: 1});
				}
			});
		});

		// Плавная прокрутка к началу страницы
		const scrollToTopButton = document.getElementById('scrollToTop');
		scrollToTopButton.addEventListener('click', () => {
			gsap.to(window, {scrollTo: 0, duration: 1});
		});


	}

	function initScrollTrigger() {
		gsap.utils.toArray(".section").forEach((panel) => {
			ScrollTrigger.create({
				trigger: panel,
				start: () => panel.offsetHeight < window.innerHeight ? "top top" : "bottom bottom",
				end: "bottom",
				pin: true,
				pinSpacing: false,
				scrub: true,

			});
		});
	}

	initScrollTrigger();
	initParallax();
	initAnimations();
});